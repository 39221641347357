<template>
  <div class="col-lg-12">
    <div class="row">
      <div class="col-lg-12">
        <div style="position: relative" class="card w-100">
          <loader-spinner no-border-radius-in-overlay centered v-if="isShowOverPageSpinner"></loader-spinner>
          <div class="d-flex flex-column justify-content-start align-items-start m-1 snmp__wrapper">
            <div class="d-flex flex-row justify-content-center align-items-center">
              <div class="d-flex flex-row justify-content-start align-items-center">
                <div class="ml-1 mr-3 h6">{{ $t('SNMP.SNMPModeSwitcher') }}</div>
<!--                <Switch-component-->
<!--                  v-model="SNMPEnabled"-->
<!--                  :disabled="isDisable"-->
<!--                  :label="$t('SNMP.onOff')"-->
<!--                  :id="'nat-access'"-->
<!--                />-->
              </div>
            </div>
            <transition name="interface">
              <div class="d-flex flex-column justify-content-center align-items-center w-100 mt-1" v-if="SNMPEnabled">
                <div class="w-100 d-flex flex-row justify-content-start">
                  <ul class="nav nav-tabs row m-0 w-100" role="tablist">
                    <li class="nav-item mr-2" v-for="tab of tabs.filter(item=>isShowTab(item))" :key="tab">
                      <a class="nav-link" role="tab" :class="{ active: activeRoute === tab }" @click="goToRoute(tab)">
                        {{ geti18nTabName(tab) }}
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="mt-1 mb-1 w-100">
                  <transition name="snmp-slide-fade" mode="out-in">
                    <router-view></router-view>
                  </transition>
                </div>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SwitchComponent from '../../../components/Universal/Switch-component.vue';

const SNMP_COMPONENTS_NAMES = [
  'SNMPGeneral',
  'SNMPViews',
  'SNMPCommunityStrings',
  'SNMPUserGroups',
  'SNMPUsers',
  'SNMPHosts',
  'SNMPWirelessTraps'
];

export default {
  name: 'SNMP',
  components: { SwitchComponent },
  data() {
    return {
      SNMPEnabled: true
    };
  },
  computed: {
    showAllSettingsOnSNMPPartitionPages() {
      // используется для задачи DF-77 чтобы временно скрыть нереализованные на бэке функции
      // в подразделах раздела SNMP
      return this.$store.state.showAllSettingsOnSNMPPartitionPages;
    },
    isDisable() {
      return this.$store.state.userData.role === 'operator';
    },
    activeRoute() {
      return this.$route.name;
    },
    tabs() {
      return SNMP_COMPONENTS_NAMES;
    },
    isShowOverPageSpinner() {
      return false;
    }
  },
  methods: {
    isShowTab(tabName) {
      if (this.showAllSettingsOnSNMPPartitionPages) {
        return true;
      }
      if (['SNMPViews', 'SNMPCommunityStrings', 'SNMPUserGroups'].includes(tabName)) {
        return false;
      }
      return true;
    },
    geti18nTabName(tabName) {
      return this.$t(`SNMP.${tabName}Header`);
    },
    goToRoute(componentName) {
      if (componentName === this.activeRoute) {
        return;
      }
      this.$router.push({ name: componentName }).catch((err) => {
        // console.log(err);
        this.$nextTick(() => {
          this.$router.push({ name: componentName });
        });
      });
    }
  }
};
</script>

<style scoped>
.snmp__wrapper {
  width: calc(100% - 2em);
}
</style>
<style>
.snmp-slide-fade-enter-active {
  transition: all 0.1s ease;
}
.snmp-slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}
.snmp-slide-fade-enter,
.snmp-slide-fade-leave-to {
  /*transform: translateY(10px);*/
  opacity: 0;
}
</style>
